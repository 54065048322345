<template>
  <el-main>
    <el-row class="home_title_1" type="flex" justify="center">
      <h1 class="home_title">一站式在线PDF解决方案</h1>
    </el-row>
    <el-row type="flex" justify="center">
      <div class="home_title_des">
        {{
          this.website_name
        }}让PDF变得简单高效！所有工具都可以免费使用，只需几秒即可完成PDF的转换、合并、压缩和拆分。
      </div>
    </el-row>
    <el-row type="flex" justify="center">
      <ContentCard></ContentCard>
    </el-row>

    <div class="weixin_box" v-if="weixin_pic">
      <div class="weixin_box_1">
        <span class="weixin_close" @click="close_weixin">
          <svg
            data-v-2bde486b=""
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="13.2427"
              y1="4.74265"
              x2="4.75743"
              y2="13.2279"
              stroke="#BABABA"
            ></line>
            <line
              x1="13.2426"
              y1="13.2279"
              x2="4.75736"
              y2="4.74263"
              stroke="#BABABA"
            ></line>
          </svg>
        </span>
      </div>
      <div class="weixin_box_2">
        <div class="block">
          <span class="demonstration"></span>
          <el-image class="weixin_img" :src="wximg"></el-image>
        </div>
        <div class="weixin_content">
            <span>微信小程序更方便哦！</span>
            <button class="weixin_button" @click="close_weixin">我知道了</button>
        </div>
      </div>
    </div>
  </el-main>
</template>

<script>
import ContentCard from "./card/index.vue";
export default {
  name: "mycontent",
  data() {
      return {
          weixin_pic: true,
          wximg: require("@/assets/wx.jpg")
      }
  },
  components: {
    ContentCard,
  },
  methods: {
      close_weixin(){
          this.weixin_pic=false
      }
  },
};
</script>

<style>
.weixin_box {
  width: 400px;
  height: 180px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 40%);
  background: rgb(255, 254, 254);
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 200;
  box-sizing: border-box;
  border-radius: 1px;
}

.weixin_box_1 {
  /* padding: 7.5px 7.5px 0 0; */
  text-align: right;
}

.weixin_close {
  cursor: pointer;
}

.weixin_box_2 {
  padding: 0 20px;
  display: flex;
}

.weixin_img{
    width: 140px;
    height: 140px;
    border-radius: 10px;
}

.weixin_content{
    color: #5a5b64;
    margin-top: 20px;
    margin-left: 10px;
    text-align: center;
}

.weixin_button{
    width: 140px;
    height: 40px;
    background: #435bfd;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    margin-top: 23px;
    cursor: pointer;
    border: none;
    outline: none;
}

.el-main {
  background: linear-gradient(
    270deg,
    rgba(42, 240, 228, 0.2),
    rgba(140, 51, 255, 0.2) 37.5%,
    rgba(255, 43, 120, 0.2) 68.75%,
    rgba(255, 188, 15, 0.2)
  );
  color: #333;
  /* align-content: center; */
  /* text-align: center; */
  /* height: 900px; */
  /* margin-top: 72px; */
}
.home_title_1 {
  margin-top: 72px;
}

.home_title {
  text-align: center;
  margin: 25px;
  font-size: 48px;
  font-weight: 600;
  color: #000;
  font-style: normal;
  font-family: Inter;
}
.home_title_des {
  text-align: center;
  width: 750px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0 auto;
  font-family: Inter;
  font-style: normal;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 20px;
}
</style>