<template>
  <div class="box1">
   
      <div class="box2">
        
          <ul>
            <li  v-for="p in info" :key="p.id">
                    <a @click="to_work(p.link)"><el-card shadow="hover" class="card">
                        <el-row>
                            <el-image style="margin:10px 0 0 15px" :src="p.img"></el-image>
                        </el-row>
                        <el-row>
                            <el-link :underline="false" style="margin:0px 0 0 20px;font-weight:bold;font-size:23px">{{p.name}}</el-link>
                        </el-row>
                        <el-row>
                            <span class="des">{{p.des}}</span>
                        </el-row>
                    </el-card></a>
            </li>
         </ul>
    </div>
  </div>
</template>

<script>

export default {
    name:"ContentCard",
    methods:{
       to_work(link) {
      this.$emit('index')

      if(link=="img_changtu"){
          this.$router.push("/upload/" + link);
      }
      else if(link=="img_to_pdf"){
         this.$router.push("/upload/" + link);

      }else if(link=="merge_pdf"){
         this.$router.push("/upload/" + link);

      }
      else{
         this.$router.push("/to_work/" + link);
      }


      


    },
    },
    data() {
        return {
            info:[
                {
                    id:'1',
                    name:'PDF转Word',
                    des:'将您的PDF文件转换WORD文档',
                    link:'pdf_to_word',
                    img:require('@/assets/img/pdf_to_word.svg')
                },
                {
                    id:'2',
                    name:'PDF转EXCEL',
                    des:'将您的PDF文件转换为EXCEL表格',
                    link:"pdf_to_excel",
                    img:require('@/assets/img/pdf_to_excel.svg')
                },
                {
                    id:'3',
                    name:'PDF转PPT',
                    des:'将您的PDF文件转换为PPT演示文件',
                    link:"pdf_to_ppt",
                    img:require('@/assets/img/pdf_to_ppt.svg')
                },
                {
                    id:'4',
                    name:'PDF转HTML',
                    des:'将您的PDF文件转换为HTML网页',
                    link:"pdf_to_html",
                    img:require('@/assets/img/pdf_to_html.svg')
                },
                {
                    id:'5',
                    name:'PDF转图片',
                    des:'将您的PDF文件转换为图片文件',
                    link:"pdf_to_img",
                    img:require('@/assets/img/pdf_to_img.svg')
                },
                {
                    id:'6',
                    name:'PDF转TXT',
                    des:'将您的PDF文件转换为TXT文件',
                    link:"pdf_to_txt",
                    img:require('@/assets/img/pdf_to_txt.svg')
                },
                {
                    id:'7',
                    name:'WORD转PDF',
                    des:'将您的WORD文档转换为PDF文件',
                    link:"word_to_pdf",
                    img:require('@/assets/img/word_to_pdf.svg')
                },
                {
                    id:'8',
                    name:'EXCEL转PDF',
                    des:'将您的EXCEL表格转换为PDF文件',
                    link:"excel_to_pdf",
                    img:require('@/assets/img/excel_to_pdf.svg')
                },
                {
                    id:'9',
                    name:'PPT转PDF',
                    des:'将您的PPT演示转换为PDF文件',
                    link:"ppt_to_pdf",
                    img:require('@/assets/img/ppt_to_pdf.svg')
                },
                {
                    id:'10',
                    name:'HTML转PDF',
                    des:'将您的HTML网页转换为PDF文件',
                    link:"html_to_pdf",
                    img:require('@/assets/img/html_to_pdf.png')
                },
                {
                    id:'11',
                    name:'图片转PDF',
                    des:'将您的图片文件转换为PDF文件',
                    link:"img_to_pdf",
                    img:require('@/assets/img/img_to_pdf.svg')
                },
                {
                    id:'12',
                    name:'TXT转PDF',
                    des:'将您的TXT文件转换为PDF文件',
                    link:"txt_to_pdf",
                    img:require('@/assets/img/txt_to_pdf.svg')
                },{
                    id:'13',
                    name:'PDF加密',
                    des:'用密码加密您的PDF',
                    link:"lock_pdf",
                    img:require('@/assets/img/lock_pdf.svg')
                },{
                    id:'14',
                    name:'PDF解密',
                    des:'删除PDF密码',
                    link:"unlock_pdf",
                    img:require('@/assets/img/unlock_pdf.svg')
                },{
                    id:'15',
                    name:'PDF加水印',
                    des:'给您的PDF文件添加水印',
                    link:"logo_pdf",
                    img:require('@/assets/img/logo_pdf.png')
                },{
                    id:'16',
                    name:'PDF去水印',
                    des:'去除您PDF文件的水印',
                    link:"unlogo_pdf",
                    img:require('@/assets/img/unlogo_pdf.png')
                },{
                    id:'17',
                    name:'PDF重置密码',
                    des:'重置您的PDF密码',
                    link:"reset_pdf",
                    img:require('@/assets/img/reset_pdf.png')
                },{
                    id:'18',
                    name:'PDF合并',
                    des:'合并PDF文件',
                    link:"merge_pdf",
                    img:require('@/assets/img/merge_pdf.svg')
                },{
                    id:'19',
                    name:'PDF拆分',
                    des:'将PDF文件拆分成多个PDF文件',
                    link:"split_pdf",
                    img:require('@/assets/img/split_pdf.svg')
                },{
                    id:'20',
                    name:'PDF压缩',
                    des:'最简单减少PDF文件大小的方法',
                    link:"zip_pdf",
                    img:require('@/assets/img/zip_pdf.svg')
                },{
                    id:'21',
                    name:'PDF旋转',
                    des:'旋转您的PDF文件方向',
                    link:"rotate_pdf",
                    img:require('@/assets/img/rotate_pdf.svg')
                },{
                    id:'22',
                    name:'PDF文字替换',
                    des:'查找并替换您PDF中的指定文字',
                    link:"pdf_replace_str",
                    img:require('@/assets/img/pdf_replace_str.svg')
                },{
                    id:'23',
                    name:'PDF文字删除',
                    des:'删除您PDF中的指定文字',
                    link:"del_str_pdf",
                    img:require('@/assets/img/del_str_pdf.png')
                },{
                    id:'24',
                    name:'PDF图片提取',
                    des:'提取包含在PDF中的所有图片',
                    link:"pdf_extract_img",
                    img:require('@/assets/img/pdf_extract_img.svg')
                },{
                    id:'25',
                    name:'PDF页面删除',
                    des:'删除PDF中的指定页码',
                    link:"del_pdf",
                    img:require('@/assets/img/del_pdf.svg')
                },{
                    id:'26',
                    name:'PDF添加页码',
                    des:'在线给PDF添加页码',
                    link:"pages_pdf",
                    img:require('@/assets/img/pages_pdf.png')
                },{
                    id:'27',
                    name:'PDF添加页眉',
                    des:'在线给PDF添加页眉',
                    link:"title_pdf",
                    img:require('@/assets/img/title_pdf.svg')
                },{
                    id:'28',
                    name:'WORD文字替换',
                    des:'给您的WORD文档替换指定的文字',
                    link:"word_str_replace",
                    img:require('@/assets/img/word_str_replace.png')
                },{
                    id:'29',
                    name:'WORD文字删除',
                    des:'查找您的WORD文档并删除指定文字',
                    link:"word_del_str",
                    img:require('@/assets/img/word_del_str.png')
                },{
                    id:'30',
                    name:'图片转素描',
                    des:'在线将图片模拟成素描画',
                    link:"img_sumiao",
                    img:require('@/assets/img/img_sumiao.png')
                },{
                    id:'31',
                    name:'图片转漫画',
                    des:'在线将图片模拟成漫画',
                    link:"img_dongman",
                    img:require('@/assets/img/img_dongman.png')
                },{
                    id:'32',
                    name:'长图拼接',
                    des:'多张图片合成一张图片',
                    link:"img_changtu",
                    img:require('@/assets/img/img_changtu.png')
                },{
                    id:'33',
                    name:'HTML转图片',
                    des:'把HTML网页转为图片文件',
                    link:"html_to_img",
                    img:require('@/assets/img/html_to_img.png')
                },{
                    id:'34',
                    name:'图片加水印',
                    des:'给您的图片文件添加水印',
                    link:"img_logo",
                    img:require('@/assets/img/logo_pdf.png')
                }
            ]
        }
    },  
}
</script>

<style scoped>
.box1{
    width: 95%;
    background: white;
    margin-bottom: 48px;
    border-radius: 0.8%;
}
.box2{
    margin: 0;
    box-sizing:border-box;
    padding: 20px 28px 16px;
}

ul{
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    /* justify-content:space-around */
}
li{
    height: 230px;
    width: 25%;
    /* background: red; */
}
.card {
    margin: 0;
    /* width: 100%; */
    height: 200px;
    margin: 0 12px 0px;
    display: flex;
    /* background: black; */
    border: 1px solid #ebebeb;
    border-radius: 3px;
    box-sizing: border-box;
}
a{
    text-decoration:none
}

.des{
    margin: 20px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(60,60,67,.6);
}


</style>